import React, { useState } from "react";
import { StyledSeeMore, StyledSeeMoreButton, StyledSeeMoreIcon } from "./style";
import expandIcon from "../../images/expand-more.svg";

interface ISeeMoreProps {
  maxHeight: number;
  children: React.ReactNode;
  label: string;
  onExpand?: () => void;
}

export default function SeeMore(props: ISeeMoreProps) {
  const { maxHeight, children, label, onExpand = () => {} } = props;
  const [expanded, setExpanded] = useState(false);

  const handleExpand = () => {
    setExpanded(true);
    onExpand();
  };

  return (
    <>
      <StyledSeeMore
        maxHeight={maxHeight}
        expanded={expanded}
        className={`collapsible ${!expanded ? "collapsed" : ""}`}
      >
        {children}
      </StyledSeeMore>
      {!expanded ? (
        <StyledSeeMoreButton onClick={handleExpand}>
          <span>{label}</span>
          <StyledSeeMoreIcon
            alt="Show all content"
            src={expandIcon}
            width={24}
            height={24}
          />
        </StyledSeeMoreButton>
      ) : (
        <></>
      )}
    </>
  );
}
