import styled from "styled-components";
import { globalColors, mediaQueries } from "../globalStyles";

export const StyledAboutMeImage = styled.img`
  border-radius: 165px;
  width: 250px;
  height: 250px;
  background: ${globalColors.imagePlaceholderBg};
  max-width: 100%;
  max-height: 250px;
  width: auto;
  height: auto;
  @media ${mediaQueries.lg} {
    margin-bottom: 2rem;
  }
`;

export const StyledAboutMeTextContainer = styled.div`
  background: #eaf7f7;
  border: 1px solid #5fc2c5;
  border-radius: 15px;
  padding: 2rem;
`;
