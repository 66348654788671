import React from "react";
import { Col, Row } from "../GridSystem";
import { trackCustomEvent } from "gatsby-plugin-google-analytics";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { StyledAboutMeImage, StyledAboutMeTextContainer } from "./style";
import SeeMore from "../SeeMore";
import { localiseText } from "../../translations";

export default function AboutMe({ sectionTitle, text, images, locale }) {
  const handleExpand = () => {
    trackCustomEvent({
      category: "Expanded about me section",
      action: "Expand",
    });
  };

  return (
    <StyledAboutMeTextContainer>
      <SeeMore maxHeight={350} onExpand={handleExpand} label={localiseText(locale, "read_more")}>
        <Row justify="between" align="center">
          <Col sm={12} md={9} lg={9}>
            <h1>{sectionTitle}</h1>
            {renderRichText(text)}
          </Col>
          <Col sm={12} md={3} lg={3} style={{ textAlign: "center" }}>
            <Row>
              {images.map((image) => (
                <Col sm={4} md={12} lg={12}>
                  <StyledAboutMeImage
                    width={250}
                    height={250}
                    src={`${image.file.url}?w=250&h=250&fit=thumb&fm=webp&q=50`}
                    alt={image.title}
                  />
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </SeeMore>
    </StyledAboutMeTextContainer>
  );
}
