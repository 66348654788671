import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import GridImages, { GridImageItem } from "../components/GridImages";
import AboutMe from "../components/AboutMe";
import EtapasProjeto from "../components/EtapasProjeto";
import InsertMetaTags from "../components/InsertMetaTags";
import Button from "../components/Button";
import plusIcon from "../images/plus-circle.svg";
import { localiseText } from "../translations";

const MainPage = ({ data: { quemSou, postsData, projectPhasesData, site: { siteMetadata: { locale } } } }) => {
  return (
    <Layout  locale={locale}>
      <InsertMetaTags />
      <section>
        <h1>{localiseText(locale, 'featured_designs_title')}</h1>
        <GridImages>
          {postsData.edges.map(({ node }) => {
            const imageSrc = `${node.mainImage.file.url}?w=800&h=600&fit=thumb&fm=webp&q=50`;
            return (
              <GridImageItem
                title={node.title}
                slug={node.slug}
                imageSrc={imageSrc}
                key={node.id}
              />
            );
          })}
        </GridImages>
        <div style={{ textAlign: "right" }}>
          <Button to="/portifolio#post_6" icon={plusIcon} fullWidth>
           {localiseText(locale, 'more_designs_btn')}
          </Button>
        </div>
      </section>
      <section>
        <AboutMe
          sectionTitle={localiseText(locale, "about_me_title")}
          text={quemSou.texto}
          images={quemSou.images}
          locale={locale}
        />
      </section>
      <section>
        <h1>{localiseText(locale, 'project_stages_title')}</h1>
        <EtapasProjeto etapas={projectPhasesData.edges} locale={locale} />
      </section>
    </Layout>
  );
};

export const query = graphql`
  query($locale: String) {
    quemSou: contentfulQuemSou(node_locale:{ eq: $locale }) {
      id
      images {
        id
        title
        file {
          url
        }
      }
      texto {
        raw
      }
    }
    postsData: allContentfulPost(filter: { node_locale: { eq: $locale  } destaque: { eq: true } }, limit: 6) {
      edges {
        node {
          id
          title
          slug
          mainImage {
            id
            title
            file {
              url
            }
          }
        }
      }
    }
    projectPhasesData: allContentfulEtapasDoProjeto(filter: { node_locale: { eq: $locale  } }, sort: { fields: ordem }) {
      edges {
        node {
          id
          ordem
          titulo
          icone {
            id
            title
            file {
              url
            }
          }
          descricao {
            id
            descricao
          }
        }
      }
    }
    site { siteMetadata { locale } }
  }
`;

export default MainPage;
