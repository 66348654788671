import React from "react";
import { trackCustomEvent } from "gatsby-plugin-google-analytics";
import { Col, Row } from "../GridSystem";
import SeeMore from "../SeeMore";
import { StyledCard } from "./style";
import { localiseText } from "../../translations";

const arrayColors = [
  "#fff5c9",
  "#c2eadb",
  "#e2e4f3",
  "#ddf4fb",
  "#fbebe1",
  "#fff5c9",
  "#c2eadb",
  "#e2e4f3",
  "#ddf4fb",
  "#fbebe1",
];

function EtapaCardCol({ cardTitle, children, icon, ...otherProps }) {
  return (
    <Col sm={12} md={6} lg={6}>
      <StyledCard {...otherProps}>
        <Row>
          <Col sm={12} md={12} lg={12}>
            <h2 style={{ display: "flex", alignItems: "center" }}>
              <img
                alt={cardTitle}
                style={{ marginRight: "1rem" }}
                src={icon}
                width={40}
                height={40}
              />
              {cardTitle}
            </h2>
          </Col>
          <Col sm={12} md={12} lg={12}>
            {children}
          </Col>
        </Row>
      </StyledCard>
    </Col>
  );
}

export default function EtapasProjeto({ etapas, locale }) {
  const handleExpand = () => {
    trackCustomEvent({
      category: "Expanded project phases section",
      action: "Expand",
    });
  };

  return (
    <SeeMore
      maxHeight={350}
      onExpand={handleExpand}
      label={localiseText(locale, "read_more")}
    >
      <Row>
        {etapas?.map(({ node }, index) => (
          <EtapaCardCol
            key={node.id}
            cardTitle={node.titulo}
            icon={node.icone.file.url}
            style={{
              background: arrayColors[index]
                ? arrayColors[index]
                : arrayColors[0],
            }}
          >
            {node.descricao.descricao}
          </EtapaCardCol>
        ))}
      </Row>
    </SeeMore>
  );
}
