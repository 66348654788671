import styled from 'styled-components'
import { StyledButton } from '../Button/style';


export const StyledSeeMore = styled.div<{maxHeight:number, expanded: boolean}>`
  &.collapsible {
    transition: max-height 2s ease-in;
    overflow: hidden;
    max-height: 5000px;
  }
  &.collapsed {
    max-height: ${({maxHeight}) => `${maxHeight}px`};
  }
`;

export const StyledSeeMoreButton = styled(StyledButton)`
  display: block;
  text-align: center;
  border: 0;
  border-top: 2px solid black;
  width: 100%;
  border-radius: 0;
  background: none;
  font-size: 1em;
  align-items: center;

  :hover,
  :focus{
    background: none;
    border: 0;
    border-top: 2px solid black;
    text-decoration: underline;
  }
`;

export const StyledSeeMoreIcon = styled.img`
  vertical-align: middle;
`;
